export default [
  // crs数据报表
  {
    path: 'groupDataIndex',
    name: 'groupDataIndex',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/group-data-index.vue'),
  },
  {
    path: 'groupDataReport',
    name: 'groupDataReport',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/group-data-report.vue'),
  },
  {
    path: 'groupHotelData',
    name: 'groupHotelData',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/group-hotel-data.vue'),
  },
  {
    path: 'groupMemberData',
    name: 'groupMemberData',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/group-member-data.vue'),
  },
  {
    path: 'groupSellData',
    name: 'groupSellData',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/group-sell-data.vue'),
  },
  {
    path: 'companyData',
    name: 'companyData',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/company-data.vue'),
  },
  {
    path: 'groupMemberSource',
    name: 'groupMemberSource',
    meta: {
      permissionCode: 'cp-group-CRSDataReportForm',
      routerType: 2,
    },
    component: () => import('../views/group-data/group-member-source/index.vue'),
  },

  // 在店数据


  {
    path: 'inHotel/index',
    name: 'inHotelData',
    meta: {
      permissionCode: 'cp-group-inHotel',
      routerType: 2,
    },
    component: () => import('../views/inhotel-data/index.vue'),
  },
  {
    path: 'inHotel/scene',
    name: 'inHotelDataScene',
    meta: {
      permissionCode: 'cp-group-inHotel',
      routerType: 2,
    },
    component: () => import('../views/inhotel-data/scene.vue'),
  },
];
