import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';
import staffs from './modules/staffs';
import common from './modules/common';
import coupon from './modules/coupon';
import product from './modules/product';
import vuetest from './modules/vuetest';
import shopService from './modules/shopService';

Vue.use(Vuex);

const filterPlugin = (store) => {
  // 当store初始化后调用
  store.subscribe((mutation, state) => {
    // 每次mutation之后调用
    console.log('store中数据发生变更', mutation, state);
  });
  
};

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    staffs,
    common,
    coupon,
    product,
    vuetest,
    shopService,
  },
  plugins: [filterPlugin],
});
