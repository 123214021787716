/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:41:51
 */
import * as types from './mutation-types';

export const addNewStaffs = ({ commit }, staff) => {
  if (staff.inventory > 0) {
    commit(types.ADD_NEW_STAFFS, {
      id: staff.id,
    });
  }
};

export default {
  addNewStaffs,
};
