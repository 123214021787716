import Vue from 'vue';
import Router from 'vue-router';
import dataRouter from './dataRouter';

const RouterView = {
  template: '<div style="background-color: white"><router-view></router-view></div>',
};
const routes = [{
  path: '/data',
  component: RouterView,
  children: [
    ...dataRouter,
  ],
}];
Vue.use(Router);

export default new Router({
  mode: 'history',
  routes,
});
