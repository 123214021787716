/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:52:03
 */
// import * as types from '../mutation-types';

// initial state
// shape: [{ id, quantity }]
const defaultState = {
  added: [],
  checkoutStatus: null,
};

// getters
const getters = {
  checkoutStatus: state => state.checkoutStatus,
};

// actions
const actions = {
  checkout() {

  },
};

// mutations
const mutations = {

};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
