/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:45:14
 */
import * as types from '../mutation-types';

// initial state
// shape: [{ id, quantity }]
const defaultState = {
  couponId: '',
  editActivityData: '',
};

// getters
const getters = {
  editActivityData: state => state.editActivityData,
};

// mutations
const mutations = {
  [types.COUPON_DETAIL_ID](state, info) {
    state.couponId = info;
  },
  [types.UPDATE_ACTIVITY_DATA](state, info) {
    state.editActivityData = info;
  },
};

export default {
  state: defaultState,
  getters,
  mutations,
};
