/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:48:57
 */
import * as types from '../mutation-types';


const defaultState = {
  shopServiceOrderNum: [],
};

const getters = {
  shopServiceOrderNum: state => state.shopServiceOrderNum,
};

const mutations = {
  [types.UPDATE_SHOPSERVICE_ORDERNUM](state, data) {
    state.shopServiceOrderNum = data;
  },
};

export default {
  state: defaultState,
  getters,
  mutations,
};
