export const ADD_NEW_STAFFS = 'ADD_NEW_STAFFS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
// 更新tabbar状态
export const UPDATE_TABBAR_STATUS = 'UPDATE_TABBAR_STATUS';
// 更新酒店的账号类型
export const UPDATE_USER_TYPES = 'UPDATE_USER_TYPES';
// 更新酒店的账号权限
export const UPDATE_USER_RIGHTS = 'UPDATE_USER_RIGHTS';
// 优惠券详情Id
export const COUPON_DETAIL_ID = 'COUPON_DETAIL_ID';
// 修改rp的参数值
export const UPDATE_EDITRP_DATA = 'UPDATE_EDITRP_DATA';
// 修改定向优惠券活动编辑的参数
export const UPDATE_ACTIVITY_DATA = 'UPDATE_ACTIVITY_DATA';
// 客房服务，各服务订单数
export const UPDATE_SHOPSERVICE_ORDERNUM = 'UPDATE_SHOPSERVICE_ORDERNUM';
