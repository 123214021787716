/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:48:21
 */
/**
 * 全局通用的状态存储
 * @author yang.xu
 */
import * as types from '../mutation-types';

const defaultState = {
  editRpData: {},
};

// getters
const getters = {
  editRpData: state => state.editRpData,
};

// mutations
const mutations = {
  [types.UPDATE_EDITRP_DATA](state, info) {
    state.editRpData = info;
  },
};

export default {
  state: defaultState,
  getters,
  mutations,
};
