/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:52:14
 */
const defaultState = {
  count: 1,
  isShowDialog: false,
};

const mutations = {
  changeCount(state, payload) {
    state.count = payload.count;
  },
  swtichDialog(state, payload) {
    state.isShowDialog = payload.isShowDialog;
  },
};

export default {
  state: defaultState,
  mutations,
};
