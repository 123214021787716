/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 13:40:19
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 13:44:57
 */
/**
 * 全局通用的状态存储
 * @author yang.xu
 */
import * as types from '../mutation-types';

const defaultState = {
  tabbarStatus: '',
  userTypes: '',
  userRights: [],
};

// getters
const getters = {
  tabbarStatus: state => state.tabbarStatus,
  userTypes: state => state.userTypes,
  userRights: state => state.userRights,
};

// mutations
const mutations = {
  [types.UPDATE_TABBAR_STATUS](state, index) {
    state.tabbarStatus = index;
  },
  [types.UPDATE_USER_TYPES](state, index) {
    state.userTypes = index;
  },
  [types.UPDATE_USER_RIGHTS](state, index) {
    state.userRights = index;
  },
};

export default {
  state: defaultState,
  getters,
  mutations,
};
